import React from 'react'
import { Card, Col, Dropdown, Row } from 'react-bootstrap'
import Chart from 'react-apexcharts';
//8 chart ke andar ka single chart hai ye
const FullChartview = ({showDifferentCharts, chartTitle, chart, row, handleCardClick}) => {
  return (
    <>
      <Col xs={12} md={4} className={`${showDifferentCharts ? 'tw-mb-8' : 'tw-mb-2'}`}>
                <Card className="h-100">
                  <Card.Header className="bgdark p-3">
                    <div className="tw-flex tw-items-center tw-justify-between mb-2 tw-w-full">
                      <h6 style={{fontSize: "1.8vh"}}>{chartTitle}</h6>
                      <div className="d-flex align-items-center gap-1">
                        {/* <small>Sort by:</small> */}
                        {/* <Dropdown>
                          <Dropdown.Toggle className="p-2 border-0" style={{backgroundColor: "#8A92A6",fontSize: "1.5vh"}} id="dropdown-basic">
                            Monthly
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{fontSize: "1.5vh"}}>
                            <Dropdown.Item href="#/action-1">Weekly</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Monthly</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Yearly</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                        <button style={{ width: "20px", height: "20px"}} className="fw-normal border-0 bg-primary px-2 rounded-2 text-primary d-flex align-items-center justify-content-center flex-column">
                          <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.19325 9.6998V8.29742C4.19324 7.94205 4.48221 7.65334 4.84015 7.65111H6.15442C6.51395 7.65111 6.80541 7.94047 6.80541 8.29742V9.69574C6.8054 10.004 7.05588 10.2545 7.36633 10.2567H8.26298C8.68175 10.2578 9.08375 10.0934 9.38025 9.79976C9.67675 9.50615 9.84338 9.10748 9.84338 8.69171V4.70814C9.84337 4.37229 9.69343 4.05372 9.43395 3.83825L6.38779 1.41965C5.85532 0.99661 5.09482 1.01028 4.57811 1.45217L1.59746 3.83825C1.32572 4.04737 1.1633 4.36688 1.15527 4.70814V8.68765C1.15527 9.55421 1.86284 10.2567 2.73567 10.2567H3.61186C3.76133 10.2578 3.90505 10.1996 4.01113 10.095C4.11721 9.99047 4.17687 9.84821 4.17687 9.6998H4.19325Z" fill="#8A92A6"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="h-100 px-0">
                    <Chart 
                    height={"100%"} options={chart.options} series={chart.series} />
                  </Card.Body>
                </Card>
            </Col>
    
            <Col className='tw-bg-green400 overflow-y-auto tw-h-[500px]'>
              <Row className="gx-2 gy-2 h-100">
                {row.map((item, index) => (
                        <Col xs={12} sm={6} md={6} key={index}>
                          <Card className="tw-h-full tw-group hover:tw-bg-[#ADB5BD]">
                          <div className="bgdark p-3">
                            <div className="tw-flex tw-items-center group-hover:tw-text-black tw-justify-between mb-2 tw-w-full">
                              <h6 className='group-hover:tw-text-black' style={{fontSize: "1.8vh"}}>{item.title}</h6>
                              <div className="d-flex align-items-center gap-1">
                                {/* <small className='tw-text-[10px]'>Full Page Sort by:</small>
                                <Dropdown>
                                  <Dropdown.Toggle className="p-1 border-0" style={{backgroundColor: "#8A92A6",fontSize: "1vh"}} id="dropdown-basic">
                                    Monthly
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu style={{fontSize: "1.5vh"}}>
                                    <Dropdown.Item href="#/action-1">Weekly</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Monthly</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Yearly</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown> */}
                                <button onClick={() => handleCardClick(item)} style={{ width: "20px", height: "20px"}} className="fw-normal border-0 bg-primary px-2 rounded-2 text-primary d-flex align-items-center justify-content-center flex-column">
                                  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4" d="M6.06474 4.92177L7.33137 3.65514C7.38997 3.59654 7.48508 3.59654 7.54368 3.65514C7.60227 3.71374 7.60227 3.80885 7.54368 3.86745L6.27705 5.13408C6.21846 5.19268 6.12334 5.19268 6.06474 5.13408C6.00615 5.07548 6.00615 4.98037 6.06474 4.92177Z" fill="#8A92A6"/>
                                    <mask id="mask0_119_7507" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="6" y="1" width="4" height="4">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.14551 4.68164L6.51757 3.0537L7.85314 1.71812L9.48108 3.34607L8.14551 4.68164Z" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask0_119_7507)">
                                    <path d="M6.62368 2.9475C6.6435 2.92768 6.66855 2.91367 6.69657 2.90716L8.52765 2.49174C8.57804 2.48042 8.63069 2.49542 8.66721 2.53194C8.70373 2.56846 8.71873 2.62111 8.70741 2.6715L8.29199 4.50259C8.27982 4.55581 8.23976 4.59841 8.18711 4.61341C8.13446 4.6287 8.07799 4.61412 8.03935 4.57548L6.62368 3.15981C6.58504 3.12117 6.57046 3.06469 6.58575 3.01204C6.59283 2.98713 6.60613 2.96505 6.62368 2.9475Z" fill="#8A92A6"/>
                                    </g>
                                    <path opacity="0.4" d="M4.93623 6.44542L3.66961 7.71205C3.61101 7.77064 3.5159 7.77064 3.4573 7.71205C3.3987 7.65345 3.3987 7.55833 3.4573 7.49974L4.72392 6.23311C4.78252 6.17451 4.87763 6.17451 4.93623 6.23311C4.99483 6.2917 4.99483 6.38682 4.93623 6.44542Z" fill="#8A92A6"/>
                                    <mask id="mask1_119_7507" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="1" y="6" width="4" height="4">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.85547 6.68555L4.48341 8.31349L3.14784 9.64907L1.5199 8.02112L2.85547 6.68555Z" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask1_119_7507)">
                                    <path d="M4.37729 8.41969C4.35748 8.4395 4.33243 8.45352 4.3044 8.46003L2.47332 8.87544C2.42294 8.88677 2.37028 8.87176 2.33377 8.83525C2.29725 8.79873 2.28225 8.74608 2.29357 8.69569L2.70898 6.8646C2.72116 6.81138 2.76121 6.76878 2.81386 6.75378C2.86652 6.73849 2.92299 6.75307 2.96163 6.79171L4.3773 8.20738C4.41594 8.24602 4.43051 8.30249 4.41523 8.35515C4.40815 8.38006 4.39485 8.40214 4.37729 8.41969Z" fill="#8A92A6"/>
                                    </g>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                            <Card.Body className='px-0 tw-h-[15vmax]'>
                              <Chart height={"100%"} options={chart.options} series={item.series} />
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
              </Row>
            </Col>
    </>
  )
}

export default FullChartview